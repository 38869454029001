<template>
  <el-card>
    <el-button type="text" style="display: flex" @click="showAdddialog"
      >添加链接+</el-button
    >
  </el-card>
  <el-card>
    <el-table :data="tableData" max-height="690px" v-loading="loading" :key="key">
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="url" label="链接">
        <template v-slot="{ row }">
          <el-link :href="row.url" target="_blank">{{ row.url }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="添加人" width="200" />
      <el-table-column prop="status" label="状态" width="100">
        <template v-slot="{ row }">
          {{ statusFilter(row.status) }}
        </template>
      </el-table-column>
      <el-table-column label="Operate" width="200">
        <template v-slot="{ row }">
          <el-button @click="modifyRow(row)">修改</el-button>
          <el-button @click="deleteRow(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-card :body-style="{ padding: '2px' }">
    <el-pagination
      v-model:currentPage="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageTotal"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>
  <el-dialog v-model="dialogShow">
    <el-form v-model="linkData" label-width="15" label-position="left">
      <el-row
        justify="center"
        style="margin-bottom: 10px; font-size: 18px; font-weight: bold"
        v-if="operateUpdate"
        >修改链接</el-row
      >
      <el-row
        justify="center"
        style="margin-bottom: 10px; font-size: 18px; font-weight: bold"
        v-if="!operateUpdate"
        >添加链接</el-row
      >
      <el-row justify="center">
        <el-form-item prop="id" label="ID" v-if="operateUpdate">
          <el-input v-model="linkData.id" disabled />
        </el-form-item>
      </el-row>
      <el-row justify="center">
        <el-form-item prop="url" label="链接">
          <el-input
            v-model="linkData.url"
            style="width: 600px"
          /> </el-form-item
      ></el-row>
      <el-row justify="center"
        ><el-form-item label-width="0">
          <el-button @click="handleModify">提交</el-button>
        </el-form-item></el-row
      >
    </el-form>
  </el-dialog>
</template>

<script>
import { addLink, delLink, getLinkList, updateLink } from "../api/spider";
const defaultPage = 1;
const defaultSize = 100;
export default {
  computed: {},
  data() {
    return {
      page: defaultPage,
      pageSize: defaultSize,
      key: "",
      pageTotal: 0,
      loading: false,
      dialogShow: false,
      operateUpdate: false,
      tableData: [],
      linkData: {
        id: 0,
        url: "",
      },
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }
    if (this.$route.query.size) {
      this.pageSize = Number(this.$route.query.size);
    }
    this.getList();
  },
  watch: {
    $route(to, from) {
      //监听相同路由下参数变化的时候，从而实现异步刷新
      //重新获取数据
      if (to.name == from.name) {
        if (this.$route.query.page) {
          this.page = Number(this.$route.query.page);
        }else{
          this.page = defaultPage;
        }
        if (this.$route.query.size) {
          this.pageSize = Number(this.$route.query.size);
        }else{
          this.pageSize = defaultSize;
        }
        this.getList();
      }
    },
  },
  methods: {
    getList() {
      this.loading = true;
      getLinkList({ page: this.page, pageSize: this.pageSize })
        .then((resp) => {
          this.tableData = resp.data.data.records;
          this.pageTotal = resp.data.data.total;
          this.key = Math.random();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    modifyRow(row) {
      this.operateUpdate = true;
      this.dialogShow = true;
      this.linkData = { id: row.id, url: row.url };
    },
    showAdddialog() {
      console.log("enter dialog");
      this.dialogShow = true;
    },
    handleModify() {
      if (this.operateUpdate) {
        // 修改
        updateLink({ id: this.linkData.id, url: this.linkData.url }).then(
          () => {
            this.dialogShow = false;
            this.getList();
          }
        );
      } else {
        // 添加
        addLink({ url: this.linkData.url }).then(() => {
          this.dialogShow = false;
          this.getList();
        });
      }
    },
    deleteRow(row) {
      delLink({ id: row.id }).then(() => {
        this.getList();
      });
    },
    statusFilter(status) {
      const statusMap = {
        0: "未爬取",
        1: "已爬取",
      };
      const statusStr = statusMap[status];
      if (statusStr) {
        return statusStr;
      }
      return "";
    },
    // 页面条数变化
    handleSizeChange(size) {
      this.pageSize = size;
      this.$router.push({
        name: "spiderList",
        query: {
          page: this.page,
          size: this.pageSize,
        },
      });
    },
    // 翻页
    handleCurrentChange(page) {
      this.page = page;
      this.$router.push({
        name: "spiderList",
        query: {
          page: this.page,
          size: this.pageSize,
        },
      });
    },
  },
};
</script>

<style scoped>
.el-card {
  margin: 5px;
}
</style>